:root{
    --green-700: #12502f;
    --green-400: #65a27b;
    --green-300: #2f9953;
    --green-50: #d5ebdd;

    --lime-500: #8eba47;

    --yellow-700: #b19e3f;
    --yellow-300: #ebd473;
    --yellow-50: #f9f2d5;
}